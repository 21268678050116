import PlaceholderCard from "./placeholder-card.component";

const LoadingItemComponent = ({ length = 8 }) => {
  return (
    <>
      {Array.from({ length }).map((_, i) => (
        <PlaceholderCard key={i} />
      ))}
    </>
  );
};

export default LoadingItemComponent;
