"use client";
import { CategoryModel } from "@/src/models/cms/category.model";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

interface IProps {
  categories?: any;
}

const NavCategoryClient = ({ categories }: IProps) => {
  const searchParams = useSearchParams();
  const [current, setCurrent] = useState(searchParams.get("category") ?? "all");

  useEffect(() => {
    const paramCategory = (searchParams.get("category") ?? "all").toLowerCase();
    if (current != paramCategory) {
      setCurrent(paramCategory ?? "");
    }
  }, [searchParams]);

  const activeClass = "text-blue-600 font-medium";

  return (
    <div className="container">
      <ul className="flex border-b border-outline-variant">
        <li
          className={`cursor-pointer hover:text-blue-600 ${
            current === "all" ? activeClass : "text-on-surface"
          }`}
        >
          <Link href="search?category=all">
            <div className="px-4 py-2 text-lg">All</div>
          </Link>
        </li>
        {categories.map((item: CategoryModel) => {
          const active =
            current === item.slug ? activeClass : "text-on-surface";
          return (
            <li
              className={`cursor-pointer hover:text-blue-600 ${active}`}
              key={item.id}
            >
              <Link href={`search?category=${item.slug}`}>
                <h2 className="px-4 py-2 text-lg">{item.name}</h2>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavCategoryClient;
