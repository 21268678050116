import { useEffect, useRef } from "react";

export default function PagedItem({
  loadNextPage,
  isLast,
  children,
  canLoad,
}: any) {
  /**
   * Select the Card component with useRef
   */
  const cardRef = useRef<HTMLDivElement | null>(null);

  /**
   * Implement Intersection Observer to check if the last Card in the array is visible on the screen, then set a new limit
   */
  useEffect(() => {
    if (!cardRef?.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (canLoad && isLast && entry.isIntersecting) {
        loadNextPage();
        observer.unobserve(entry.target);
      }
    });

    observer.observe(cardRef.current);
  }, [isLast]);

  return <div ref={cardRef}>{children}</div>;
}
