import { format } from "@/src/lib/dayjs";
import Link from "next/link";
import BlurImage from "../blur-image";
import AppLogo from "../layout-public/app-logo";

export default function PostCardComponent({
  title,
  coverImage,
  date,
  excerpt,
  author,
  slug,
  link,
  priority,
}: any) {
  return (
    <div className="link-effect app-card cursor-pointer">
      <div className="relative aspect-video overflow-hidden rounded-md bg-gray-100 dark:bg-gray-800">
        <Link
          href={link ? link : `/posts/${slug}`}
          target={link ? "_blank" : ""}
          prefetch={false}
        >
          <BlurImage
            src={coverImage || "/empty-state.png"}
            alt={title}
            priority={priority}
          />
        </Link>
      </div>
      <div className="px-3 pb-3">
        <h2 className="text-brand-primary mt-2 text-lg font-semibold tracking-normal dark:text-white">
          <Link href={`/posts/${slug}`}>
            <span className="link-underline link-underline-blue">{title}</span>
          </Link>
        </h2>

        <div className="hidden">
          {excerpt && (
            <p className="mt-2 line-clamp-3 text-sm text-gray-500 dark:text-gray-400">
              <Link href={`/post/${slug?.current}`}>{excerpt}</Link>
            </p>
          )}
        </div>

        <div className="mt-3 flex items-center space-x-3 text-gray-500 dark:text-gray-400">
          <div className="flex items-center gap-3">
            <div className="relative h-5 w-5 flex-shrink-0">
              <AppLogo />
            </div>
            <span className="text-sm">{author?.name || "admin"}</span>
          </div>
          <span className="text-xs text-gray-300 dark:text-gray-600">
            &bull;
          </span>
          <time className="text-sm" dateTime={date}>
            {format(date, "MMMM DD, YYYY")}
          </time>
        </div>
      </div>
    </div>
  );
}
