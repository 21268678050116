"use client";
import Image from "next/image";
import { useState, type ComponentProps } from "react";
export const runtime = "edge";
interface IProps extends ComponentProps<typeof Image> {
  zoomLoading?: boolean;
}
export default function BlurImage(props: IProps) {
  const { zoomLoading = true } = props;
  const restProps = { ...props };
  delete restProps.zoomLoading;
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Image
      onLoad={() => setIsLoading(false)}
      {...restProps}
      className={`
        relative object-cover duration-700 ease-in-out
        ${props.className || ""} 
        ${!props.height ? "h-auto w-full" : ""}
        ${
          isLoading && zoomLoading
            ? "scale-110 blur-2xl grayscale"
            : "scale-100 blur-0 grayscale-0"
        }
      `}
      loading={props.priority ? "eager" : "lazy"}
      alt={props.alt}
      fill={props.height || props.width ? undefined : true}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      quality={75}
    />
  );
}
